import { useDataStore } from './data/store'
import { Vis } from './components/Vis'
import { useEffect } from 'react'
import { useOwnerStore } from './data/ownerStore'
import { useIssuesStore } from './data/issuesStore'
import { DocModal } from './components/DocModal'
import { SidePanel } from './components/SidePanel'
import { ForceVis } from './3d/ForceVis'
import { useForceStore } from './data/forceStore'
import { FullScreen } from './ds/Position'
import { Switch, Route } from 'wouter'
import { useBubbleStore } from './issuesVis/bubbleStore'
import BubblesApp from './issuesVis/BubblesApp'
import { Legend } from './components/Legend'

import { FirebaseAppProvider } from 'reactfire'
import { firebaseConfig } from './lib/firebase'
import { Authorisation } from './components/Authorisation'

import * as Dialog from './ds/OpeningDialog'
import { Logo } from '@smash-delta/logo'
import TransportLogo from './components/TransportLogo'

export default function App() {
    useEffect(() => {
        useDataStore.getState().fetch()
        useOwnerStore.getState().fetch()
        useIssuesStore.getState().fetch()
        useForceStore.getState().fetch()
        useBubbleStore.getState().fetch()
    }, [])

    return (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <Authorisation fallback={null}>
                <Switch>
                    <Route path="/">
                        <Vis />
                    </Route>
                    <Route path="/force">
                        <FullScreen css={{ backgroundColor: '$beige' }}>
                            <ForceVis />
                            <Legend forForceVis />
                        </FullScreen>
                    </Route>
                    <Route path="/issues">
                        <FullScreen>
                            <BubblesApp />
                        </FullScreen>
                    </Route>
                </Switch>
            </Authorisation>

            <SidePanel />

            <Authorisation fallback={null}>
                <DocModal />
            </Authorisation>

            <Dialog.Root defaultOpen>
                <Dialog.Overlay></Dialog.Overlay>

                <Dialog.Content>
                    <div>
                        <Dialog.Title>
                            N&A - Operational Intelligence Ecosystem
                        </Dialog.Title>
                        <Dialog.SubTitle>Prototype - Live Beta</Dialog.SubTitle>
                    </div>
                    <Dialog.P size="lg">
                        This Operational Intelligence Ecosystem explorer is a
                        non-exhaustive view of key issues and elements as at May
                        21 2021. Please use in conjunction with the OIS Project
                        Update - May 21 2021 document.
                    </Dialog.P>
                    <Dialog.P size="lg">
                        This live-beta version of the Operational Intelligence
                        Ecosystem explorer was seeded with a non-exhaustive view
                        of key issues and elements captured by May 21 2021.
                        Information may have been uodated since then. Please use
                        in conjunction with the OIS Project Update - May 21 2021
                        document.
                    </Dialog.P>
                    <Dialog.P size="lg">
                        This experience has been optimised for Chrome and Edge,
                        and may not work or perform well on other or older
                        browsers.
                    </Dialog.P>

                    <Dialog.LogoGroup>
                        <Logo className={Dialog.sdLogo()} />
                        <Dialog.LogoSeperator orientation="vertical" />
                        <TransportLogo className={Dialog.tfnswLogo()} />
                    </Dialog.LogoGroup>

                    <div>
                        <Dialog.Close>Close</Dialog.Close>
                    </div>
                </Dialog.Content>
            </Dialog.Root>
        </FirebaseAppProvider>
    )
}
