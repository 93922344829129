import sanityClient, { SanityDocument } from '@sanity/client'
import { useEffect, useState } from 'react'

export const client = sanityClient({
    projectId: '3ja961t9',
    dataset: 'beta',
    useCdn: true,
    apiVersion: '2021-05-24',
    // Read only token
    token: 'skFRrum8M8Dz7mpm7Nzon16caY7P5NpXRqVDjPcdNYOgkRoKbUwUMV6cDlmfBxP8NZFJHNFSxDsRj700BcV84ygIwzTgqB0bDRxGN6ziZ6FhyqUmjl1VqQLj9E34NpAwNPmV0q7qX0ZAe4CEGVOGVe0yDCe8uI3e13rCHkR2Byr6sTpEuz1g',
})

export const sanityFetcher = (query: string) => client.fetch(query)

export const sanityDocumentFetcher = (id: string) => client.getDocument(id)

export function useSanityTypeQuery<T>(type: string) {
    const [state, setState] = useState<SanityDocument<T>[]>()

    useEffect(() => {
        client.fetch(`*[_type == "${type}"]`).then((data) => setState(data))
    }, [type])

    return state
}

export function useSanityMultiTypeQuery<T>(types: string[]) {
    const [state, setState] = useState<SanityDocument<T>[]>()
    const query = `*[_type in ${JSON.stringify(types)}]`

    useEffect(() => {
        client.fetch(query).then((data) => setState(data))
    }, [query])

    return state
}

export function useSanityQuery<T>(query: string, initial: T) {
    const [state, setState] = useState<T>(initial)

    useEffect(() => {
        client.fetch(query).then((data) => setState(data))
    }, [query])

    return state
}
