import { client } from '../lib/sanity'
import {
    getConnectedEdges,
    getIncomers,
    getOutgoers,
    isEdge,
    isNode,
    XYPosition,
    Node,
    Edge,
} from 'react-flow-renderer'
import create, { State } from 'zustand'
import { Doc, EcoEdge, EcoNode } from './api'
import uniq from 'lodash.uniq'
import { config } from '../stitches.config'
const tc = config.theme.colors

export type DocsById = Record<string, Doc>

export const query = `*[
    _type in [
        "person", "cluster", "division", "branch", "section",
        "kpi", "report", "system", "dataSource", "analyticalOutput",
        "edge", "issue"
    ]
]`

export type FNode = Node<EcoNode>
export type FEdge = Edge<EcoEdge>
export type FElement = FNode | FEdge

export interface Store extends State {
    ready: boolean
    docs: DocsById

    // For the vis
    elements: FElement[]
    fetch: () => void
    updatePosition: (id: string, position: XYPosition) => void
    highlighted: string[]
    onSelectionChange: (selectedElements: FElement[] | null) => void
    dialogDocumentID: string | null
    setDialogDocumentID: (id: string | null) => void

    sourceIssueElements: string[] | null
    connectedIssueElements: string[] | null
    highlightIssues: (nodes: EcoNode[], edges: EcoEdge[]) => void
    clearIssueHighlights: () => void

    highlightTBDs: boolean
    toggleHighlightTBDs: () => void
}

function toFnode(d: EcoNode): FNode {
    return {
        id: d._id,
        data: d,
        position: {
            x: d.position?.x || 0,
            y: d.position?.y || 0,
        },
        type: 'custom',
    }
}

function toFEdge(d: EcoEdge): FEdge {
    return {
        id: d._id,
        source: d.source._ref,
        target: d.target._ref,
        data: d,
        type: 'custom',
    }
}

export const useDataStore = create<Store>((set, get) => ({
    ready: false,
    docs: {},

    elements: [],
    async fetch() {
        const response = await client.fetch<Doc[]>(query)
        const docsById: DocsById = {}
        const nodes: EcoNode[] = []
        const edges: EcoEdge[] = []
        response.forEach((doc) => {
            switch (doc._type) {
                case 'kpi':
                case 'analyticalOutput':
                case 'dataSource':
                case 'system':
                case 'report':
                    nodes.push(doc)
                    break
                case 'edge':
                    edges.push(doc)
                    break
            }
            docsById[doc._id] = doc
        })

        const flowEdges = edges.map(toFEdge)
        const flowNodes = nodes.map(toFnode)
        const elements = [...flowNodes, ...flowEdges]

        set({
            docs: docsById,
            elements,
            ready: true,
        })
    },
    updatePosition(id, position) {
        // optimistic
        set((state) => ({
            docs: {
                ...state.docs,
                [id]: {
                    ...state.docs[id],
                    position,
                },
            },
        }))
        // We wont actually update on our CSB
        // client.patch(id).set({ position }).commit()
    },
    // Highlighting on Select
    highlighted: [],
    onSelectionChange(selectedElements) {
        const { elements } = get()

        if (selectedElements && selectedElements.length > 0) {
            const nodes = selectedElements.filter(isNode)

            // Only highlighting selections from edges
            if (nodes.length === 0) {
                set({ highlighted: [] })
                return
            }

            const edges = elements.filter(isEdge)

            const connectedEdges = getConnectedEdges(nodes, edges).map(
                (d) => d.id,
            )
            const connectedNodes = nodes
                .flatMap((node) => [
                    getIncomers(node, elements),
                    getOutgoers(node, elements),
                ])
                .flatMap((d) => d)
                .map((d) => d.id)

            const highlighted = connectedEdges.concat(connectedNodes)
            set({ highlighted })
        } else {
            set({ highlighted: [] })
        }
    },
    // Doc Dialog
    dialogDocumentID: null,
    setDialogDocumentID(dialogDocumentID) {
        set({ dialogDocumentID })
    },

    // Issue Pathing
    sourceIssueElements: null,
    connectedIssueElements: null,

    clearIssueHighlights() {
        set({ sourceIssueElements: null, connectedIssueElements: null })
    },

    highlightIssues(_nodes, _edges) {
        const { elements } = get()
        const allEdges = elements.filter(isEdge)

        const nodes = _nodes.map(toFnode)
        const edges = _edges.map(toFEdge)

        const sourceIssueElements = nodes
            .map((d) => d.id)
            .concat(edges.map((d) => d.id))

        set({ sourceIssueElements })

        // const sourceNodes = uniq(nodes.concat(sourceConnectingNodes))
        const connectedEdges = getConnectedEdges(nodes, allEdges).map(
            (d) => d.id,
        )

        // When edges are selected we want to start our traversal from the connecting nodes
        const sourceEdgeConnectingNodes = edges.flatMap(
            ({ source, target }) => [
                elements.find((e) => e.id === source),
                elements.find((e) => e.id === target),
            ],
        ) as FNode[]

        const connectedNodes = nodes
            .flatMap((node) => [
                getIncomers(node, elements),
                getOutgoers(node, elements),
            ])
            .flatMap((d) => d)
            .map((d) => d.id)
            .concat(sourceEdgeConnectingNodes.map((d) => d.id))

        const connectedIssueElements = uniq(
            connectedEdges.concat(connectedNodes),
        )

        set({
            connectedIssueElements,
        })
    },

    highlightTBDs: false,
    toggleHighlightTBDs() {
        set((s) => ({ highlightTBDs: !s.highlightTBDs }))
    },
}))

const colors: Record<EcoNode['_type'], string> = {
    dataSource: tc.blue500,
    kpi: tc.orange500,
    system: tc.pink500,
    report: tc.green500,
    analyticalOutput: tc.teal500,
}

export function minimapNodeColor(node: FNode) {
    return node.data ? colors[node.data._type] : '#ffffff'
}
